<div id="onboarding emptystate" >

    <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
    <app-header></app-header>

      <div class="container-fluid emptystate-onboarding">
          <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
              <main class="emptystate-center">
          <p class="lead">Bienvenido a tu portal de administración de Billcentrix,
  estás a pocos pasos de tener tu negocio recurrente.</p>
                  <p class="lead">
                      <button (click)="goStep()" class="btn btn-primary btn-user btn-block">
                                              {{ buttonText }}
                      </button>
                  </p>
              </main>
          </div>


      </div>


</div>
