<div class="content-plan">
  <div class="content-form">

    <div>
      <button (click)="dialogRef.close()" class="btn-default btn"   style="float: right; background : #15448f; color: white;">X</button>
    </div>

    <div class="rendered-form">

      <form [formGroup]="form" (ngSubmit)="send()">

        <div style="text-align: center">
          <h5 access="false" id="control-1373969">Datos de tarjeta de Crédito</h5>
        </div>

        <div class="two-column-form">
          <div class="form-group field-tc">
            <input [formControl]="form.controls['nameHolderCreditCard']" type="text" placeholder="Nombre del tarjeta habiente" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">
            <small
              *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched"
              class="form-error-msg"> Nombre es requerido </small>
          </div>
          <div class="form-group field-Numero-de-Tarjeta">


            <input (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['numberCreditCard']"  type="text" placeholder="Número de Tarjeta" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">

            <small
              *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched"
              class="form-error-msg"> Número es requerido </small>
          </div>
        </div>
        <div class="two-column-form">
          <div class="form-group field-mes-exp">
            <label for="mes-exp"></label>
            <select  [formControl]="form.controls['expMonthCreditCard']" class="form-control" name="mes-exp" id="mes-exp">
              <option disabled="" selected="" value="" >Mes de expiracion</option>
              <option value="01" id="mes-exp-0">Enero</option>
              <option value="02" id="mes-exp-1">Febrero</option>
              <option value="03" id="mes-exp-2">Marzo</option>
              <option value="04" id="mes-exp-3">Abril</option>
              <option value="05" id="mes-exp-4">Mayo</option>
              <option value="06" id="mes-exp-5">Junio</option>
              <option value="07" id="mes-exp-6">Julio</option>
              <option value="08" id="mes-exp-7">Agosto</option>
              <option value="09" id="mes-exp-8">Septiembre</option>
              <option value="10" id="mes-exp-9">Octubre</option>
              <option value="11" id="mes-exp-10">Noviembre</option>
              <option value="12" id="mes-exp-11">Diciembre</option>
            </select>

            <small
              *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched"
              class="form-error-msg"> Mes es requerido </small>
          </div>
          <div class="form-group field-ano-exp">
            <label for="ano-exp"></label>
            <select [formControl]="form.controls['expYearCreditCard']" class="form-control" name="ano-exp" id="ano-exp">
              <option disabled="" selected="" value="" >Año de expiracion</option>
              <option value="22" id="ano-exp-0">2022</option>
              <option value="23" id="ano-exp-1">2023</option>
              <option value="24" id="ano-exp-2">2024</option>
              <option value="25" id="ano-exp-3">2025</option>
              <option value="26" id="ano-exp-4">2026</option>
              <option value="27" id="ano-exp-5">2027</option>
              <option value="28" id="ano-exp-6">2028</option>
              <option value="29" id="ano-exp-7">2029</option>
              <option value="30" id="ano-exp-8">2030</option>
              <option value="31" id="ano-exp-9">2031</option>
              <option value="32" id="ano-exp-10">2032</option>
              <option value="33" id="ano-exp-11">2033</option>
              <option value="34" id="ano-exp-12">2034</option>
              <option value="35" id="ano-exp-13">2035</option>
              <option value="36" id="ano-exp-14">2036</option>
              <option value="37" id="ano-exp-15">2037</option>
              <option value="38" id="ano-exp-16">2038</option>
              <option value="39" id="ano-exp-17">2039</option>
              <option value="40" id="ano-exp-18">2040</option>
            </select>
            <small
              *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched"
              class="form-error-msg"> Año es requerido </small>
          </div>
          <div class="form-group field-CCV">
            <label for="CCV"></label>
            <input (keypress)="onlyNumberKey($event)" [formControl]="form.controls['ccv']" type="text" placeholder="CCV" class="form-control" name="CCV" access="false" id="CCV">
            <small
              *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched"
              class="form-error-msg"> ccv es requerido </small>
          </div>
        </div>
        <div class="bot form-group field-suscribirte">
          <button type="submit" class="btn-default btn" name="suscribirte" access="false" id="suscribirte">suscribirte</button>
        </div>

      </form>

    </div>

  </div>
</div>

