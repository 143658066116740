
import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanService} from "./plan.service";


@Component({
  selector: 'app-list-portal-plans',
  templateUrl: './list-portal-plans.component.html',
  styleUrls: ['./list-portal-plans.component.css']
})
export class ListPortalPlansComponent implements OnInit {

  private onboardingConfigID: any;

  constructor(private planService : PlanService, private router : Router,private activateRoute : ActivatedRoute,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }


  ngOnInit(): void {
    this.activateRoute.params.subscribe(params =>{
      this.onboardingConfigID = params.id;
      this.getConfigByID();
    }, err =>{
      this.toast.showError(err);
    });

  }

  getConfigByID(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      this.planService.plans.emit(config.result.plans)
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  goCreatePlan(){
    this.router.navigate(['create'], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

}
