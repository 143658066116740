import { Component,ElementRef, OnInit,ViewChild } from '@angular/core';
import { ToastService } from '../../../../services/notification/toast.service';
import { WebService}  from '../../../../services/web/web.service';
import {SpinnerService} from '../../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {Location} from "@angular/common";


@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.css']
})
export class CreatePlanComponent implements OnInit {

  plans : any = [];

  form: FormGroup ;

  frecuencies : any;
  @ViewChild('inputFile') myInputVariable: ElementRef | undefined;

  imageName = null;
  image64: any;
  imageType: any;
  onboardingConfigID: any;

  constructor(private location : Location,private activateRoute : ActivatedRoute, private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService ) {

    this.form = new FormGroup({
      name_plan: new FormControl('', Validators.required),
      feeCost_plan: new FormControl('', Validators.required),
      freezeCostPlan : new FormControl('', Validators.required),
      enrollmentCost_plan: new FormControl(0),
      frecuency : new FormControl('', Validators.required),
      status_plan : new FormControl(1, Validators.required),
      taxRate_plan : new FormControl('', Validators.required),
      service: new FormControl(''),
      description : new FormControl('', Validators.required),
      idPlan_partner : new FormControl("123", Validators.required),
      cobro_by_partner : new FormControl(false, Validators.required),
      verify_invoices_on_partner : new FormControl(false, Validators.required),
      charge_inmediately : new FormControl(false, Validators.required)
    });

  }

  ngOnInit(): void {
    this.spinner.open();

    this.webService.get(this.webService.HOST + "/frecuency").subscribe( response =>{
      this.frecuencies = response.result;
      this.activateRoute.params.subscribe(params =>{

        this.onboardingConfigID = params.id
        this.getConfigByID();

      }, err =>{
        this.toast.showError(err);
      });
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  getConfigByID(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      this.form.controls["service"].setValue({id:config.result.service.id});
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  myportals(){
    this.toast.showInfo("Funcionalidad en desarrollo, debe mostrar pantalla con portales")
  }

  send(){
    this.form.value.frecuency = { id : +this.form.value.frecuency }
    this.form.value.image64 = this.image64;
    this.form.value.imageName = this.imageName;
    this.form.value.imageType = this.imageType;
    this.form.value.onboarding_config = { id :  +this.onboardingConfigID }

    if(!this.form.valid){
      return this.toast.showError("Porfavor, complete el formulario...")
    }

    this.spinner.open();

    this.webService.post({plan:this.form.value, onboarding:true}, this.webService.HOST + "/plan").subscribe(response =>{

      this.spinner.close();
      this.toast.showSuccess("Plan creado correactamente...");
      // @ts-ignore: Object is possibly 'null'.
      this.myInputVariable.nativeElement.value = '';
      this.location.back();


    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });


  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageName = file.name;
        this.imageType = file.type;
        this.image64 = reader.result!.toString();
      };
    }
  }

}
