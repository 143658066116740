import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {PaymentPopupComponent} from "./payment-popup/payment-popup.component";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.css']
})
export class Step4Component implements OnInit {

  plans : any = [];

  form: FormGroup ;

  @ViewChild('inputFile') myInputVariable: ElementRef | undefined;


  frecuencies : any;

  imageName = null;
	image64: any;
	imageType: any;
  onboardingConfigID: any;
  showPaymentButton : Boolean = false;
  goToMyPortals : Boolean = false;

  constructor(private dialog: MatDialog,private activateRoute : ActivatedRoute, private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService ) {
    this.plans.push(1);

    this.form = new FormGroup({
     // id: new FormControl(''),
      name_plan: new FormControl('', Validators.required),
      feeCost_plan: new FormControl('', Validators.required),
      freezeCostPlan : new FormControl('', Validators.required),
      enrollmentCost_plan: new FormControl(0),
      frecuency : new FormControl('', Validators.required),
      status_plan : new FormControl(1, Validators.required),
      taxRate_plan : new FormControl('', Validators.required),
      service: new FormControl(''),
      description : new FormControl('', Validators.required),
      idPlan_partner : new FormControl("123", Validators.required),
      cobro_by_partner : new FormControl(false, Validators.required),
      verify_invoices_on_partner : new FormControl(false, Validators.required),
      charge_inmediately : new FormControl(false, Validators.required)
    });

  }

  ngOnInit(): void {
    var sesion = JSON.parse(localStorage.getItem('sesion')!);

      this.spinner.open();

      this.webService.get(this.webService.HOST + "/frecuency").subscribe( response =>{
        this.frecuencies = response.result;
        this.activateRoute.queryParams.subscribe(queryParams =>{
          if(queryParams.config){
            this.onboardingConfigID = +queryParams.config;
            this.habilitatePaymentButton();
            this.getConfigByID();
          }else{
            if(sesion.config !=  null){
              if(sesion.config.length === 1){
                this.onboardingConfigID = +sesion.config[0];
                this.habilitatePaymentButton();
                this.getConfigByID();
              }
            }
          }
        }, err =>{
          this.toast.showError(err);
        });
      }, err =>{
        this.spinner.close();
        this.toast.showError(err);
      });


  }

  habilitatePaymentButton(){
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID ).subscribe( response =>{
      if(response.result.service.current_onboarding_step == 5){
        this.showPaymentButton = true;
      }

      if(response.result.service.current_onboarding_step == 5 || response.result.service.current_onboarding_step == 6 ){
        this.goToMyPortals = true;
      }

    }, err =>{
      this.toast.showError(err);
      this.spinner.close();
    });
  }

  getConfigByID(){
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      this.form.controls["service"].setValue({id:config.result.service.id});
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  myportals(){
    this.toast.showInfo("Funcionalidad en desarrollo, debe mostrar pantalla con portales")
  }

  send(){

    this.form.value.frecuency = { id : +this.form.value.frecuency }
    this.form.value.image64 = this.image64;
    this.form.value.imageName = this.imageName;
    this.form.value.imageType = this.imageType;
    this.form.value.onboarding_config = { id :  this.onboardingConfigID }

    if(!this.form.valid){
      return this.toast.showError("Porfavor, complete el formulario...")
    }

    this.spinner.open();

    this.webService.post({plan:this.form.value, onboarding:true}, this.webService.HOST + "/plan").subscribe(response =>{

      this.spinner.close();
      this.toast.showSuccess("Plan creado correactamente...");
      this.form.reset();
      this.form.controls["idPlan_partner"].setValue("123");
      this.form.controls["status_plan"].setValue(1);
      this.form.controls["cobro_by_partner"].setValue(false);
      this.form.controls["verify_invoices_on_partner"].setValue(false);
      this.form.controls["charge_inmediately"].setValue(false);
      // @ts-ignore: Object is possibly 'null'.
      this.myInputVariable.nativeElement.value = '';
      this.ngOnInit();

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });


  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
      this.imageName = file.name;
      this.imageType = file.type;
      this.image64 = reader.result!.toString();
      };
    }
  }

  goBack(){
    this.router.navigate(["/step3"], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }


  processPayment(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      })
  }

}
