import { Injectable } from "@angular/core";
import {AuthService} from '../services/auth/auth.service';
import { ToastService } from '../services/notification/toast.service';
import { Location } from '@angular/common';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { WebService}  from '../services/web/web.service';
import {SpinnerService} from '../services/spinner/spinner.service';
//import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private auth : AuthService,private toast : ToastService,private location : Location,private spinner : SpinnerService,private webService : WebService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    /* let time = this.auth.getTime();
      let timeLoggin = new Date(time); // TIEMPO EN EL QUE VENCE LA SESION DEL USUARIO
      let timeActual = new Date(); // HORA ACTUAL
      console.log(timeActual);
      console.log(timeLoggin);
      let permission = true; // EN PRINCPIO CONCEDEMOS EL PERMISO
      if(this.auth.getTime() != null){ // ESTO EN CASO DE QUE EL USUARIO YA HAYA INICIADO SESION
          if(timeLoggin <= timeActual || timeActual.getFullYear() != timeLoggin.getFullYear()){ // COMPARAMOS LA FECHAS
            permission = false;
          }
      }*/

    if (this.auth.isLoggedIn()) { // LO DEJAMOS AVANZAR SI ESTA LOGEADO Y SI NO SE LE HA VENCIDO LA SESION, TIENE PERMISO DE ACANZAR

      if(route.data.step !== undefined){ // AQUI COMPROBAMOS SI ESTA INTENTANDO NAVEGAR A ALGUNO DE LOS STEPS DE CONFIGURACION DE CUENTA

        let partner = JSON.parse(localStorage.getItem('sesion')!).partner;
        let step = Number(route.data.step);

        if( step != 1 ){
          if(partner == null && (step == 2 || step == 3 || step == 4 ) ){ // partner == null => SIGNIFICA QUE NO HAY PARTNER CREADO ASOCIADO A ESTE CLIENTE- POR LO QUE NO PUEDE AVANZAR EN NADA DENTRO DE LA APP

            this.toast.showError("No puedes avanzar hasta haber creado tu compañía");
            this.router.navigateByUrl("/step1");

          }else{ // AQUI QUIERE DECIR QUE YA SE CREO LA CAOMPANIA, PUEDE AVANZAR A LOS OTROS STEPS
            this.spinner.open();
            // CONSULTAMOS LA INFORMACION DEL STEP DEL PARTNER
            this.webService.get(this.webService.HOST + "/billcentrix/V2/step/partner/" + partner ).subscribe(response =>{
                this.spinner.close();
                // TODO : AQUI SE DEBE CONDULTAR POR EL ESTATUS ACTUAL DE LOS STEPS DEL PARTNER, PARA DARLE ACCESO DEACUERDO A SU NIVEL
                // QUE current_step = 2 QUIERE DECIR QUE YA CREO SU COMPANIA AHORA  PERO NO HA SELECCIONADO SU PLANTILLA O TEMPLATE
                // POR LO TANTO NO PUEDE AVANZAR AL PASO 3 HASTA NO CULMINAR LA SELECCION
                if( response.result.current_step == 2 &&  (step == 3 || step == 4 )){
                  this.toast.showError("No puedes acceder a este paso, sin antes haber completado los pasos anteriores")
                  this.router.navigateByUrl("/step2");
                }
                // QUE current_step == 3 QUIERE DECIR QUE YA SELECCIONO SU TEMPLATE, PERO NO HA SELECCIONADO LA PALETA DE COLORES Y TAMPOCO SUBIDO LOGOS -> POR LO CUAL NO PUEDE ACCEDER
                // AL PASO 4
                if( response.result.current_step == 3 && step == 4 ){ // QUE U
                  this.toast.showError("No puedes acceder a este paso, sin antes haber completado los pasos anteriores")
                  this.router.navigateByUrl("/step3");
                }
                // AQUI SE CONTEMPLA QUE EL current_step sea != de 1-2-3, YA ES 4 O MAS POR LO CUAL PUEDE CONFIGURAR SUS PLANES
              }, err => {
                this.spinner.close();
                this.toast.showError(err);
            });
          }
        }

      }

      return true;
    } else { // CASO CONTRARIO LO MANDAMOS A INICIAR SESION NUEVAMENTE
      localStorage.removeItem(this.auth.APP_USER_TIME);
      this.router.navigate(["/unauthorized"], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }

  }
}
