<div class="row">

  <div *ngFor=" let plan of myplans " class="col-lg-4">
    <div class="card card-sm card-transition" >
      <img style="height: 50%;pointer-events: none;" [src]="plan.url_image" >
      <div class="card-body">
        <h2 class="card-title text-inherit">{{ plan.name_plan }}</h2>
        <a *ngIf="plan.status_plan == 2" (click)="onUpdateStatus(plan.id,1)" style="float: left; cursor: pointer; color: blue" >Activar plan</a>
        <a *ngIf="plan.status_plan == 1" (click)="onUpdateStatus(plan.id,2)" style="float: left; cursor: pointer; color: blue" >Desactivar plan</a>
        <a [routerLink]="['update', plan.id]" style="float: right; cursor: pointer; color: blue" >Editar</a>
      </div>
    </div>
  </div>

</div>
