import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-new-account',
  templateUrl: './create-new-account.component.html',
  styleUrls: ['./create-new-account.component.css']
})
export class CreateNewAccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
