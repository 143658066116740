import { Component, OnInit } from '@angular/core';
import { WebService}  from '../../../services/web/web.service';
import { ToastService } from '../../../services/notification/toast.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {
  Router,
} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  exist : boolean = false;
  partner : any;
  config: any;

  buttonText : any = "Crear mi primer portal";

  constructor(private router: Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService) { }

  ngOnInit(): void {

    this.partner = JSON.parse(localStorage.getItem('sesion')!).partner;
    this.config = JSON.parse(localStorage.getItem('sesion')!).config;

    if(this.partner != undefined){
      this.exist = true;
      if(this.config.length == 1){
        this.buttonText = "Continuar con mi portal";
      }else if(this.config.length > 1){
        this.buttonText = "Ver mis portales";
      }

    }

  }

  // FUNCION PARA CONTROLAR DEPENDIENDO DEL ESTEP DONDE SE ENCUENTRE EL CLIENTE, LLEVARLO A SU SIGUIENTE STEP
  goStep(){

    if(!this.exist){ // EN CASO DE QUE NO HAYA PARTNER CREAO ENTONCES LO ENVIAMOS AL STEP1
        this.router.navigateByUrl("/step1");
        return;
    }else{
      this.spinner.open()
      // AQUI EL PARTNER SI EXISTE, POR LO TANTO CONSULTAMOS EN QUE STEP SE ENCUENTRA
      if(this.config.length == 0 || this.config.length == 1 ){
        this.webService.get(this.webService.HOST + "/billcentrix/V2/step/partner/" + this.partner ).subscribe(response =>{
          // AQUI CONSIDERAMOS LOS DISTINTOS STEPS RESTANTES
          this.spinner.close();
          switch(response.result.current_step){
            case 2 : this.router.navigateByUrl("/step2");
              break;
            case 3 : this.router.navigateByUrl("/step3");
              break;
            default: this.router.navigateByUrl("/step4");  // CUALQUIERA DISTINTO A LOS ANTERIORES ENTONCES LO ENVIAMOS AL PASO FINAL
          }

        }, err => {
          this.spinner.close();
          this.toast.showError(err);
        });
      }else{ // EN CASO QUE TENGA MAS DE 1 PORTAL ENTONCES LO ENVIAMOS A SUS PORTALES
        this.router.navigateByUrl("/portals");
      }
    }

  }

}
