<div class="p-10">
    <div class="logo-login">
        <img src="assets/img/billcentrix.svg">
    </div>
    <div class="">
        <h1 class="h4 text-gray-900 mb-4">Iniciar sesión</h1>
    </div>
    <form class="user" [formGroup]="signinForm" (ngSubmit)="signin()" >
        <div class="form-group">
                <input [formControl]="signinForm.controls['username']"   type="email" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Ingresa email...">
                
                        <small 
                        *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched" 
                        class="form-error-msg"> Correo es requerido </small>
                        <small 
                        *ngIf="signinForm.controls['username'].hasError('email') && signinForm.controls['username'].touched" 
                        class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>

        </div>
            <div class="form-group">
                    <input [formControl]="signinForm.controls['password']"  type="password" class="form-control form-control-user" id="exampleInputPassword" placeholder="Ingresa password...">
                    
                    <small 
                    *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched" 
                    class="form-error-msg"> Contraseña es requerido </small>

                </div>
            <div class="form-group">
                    <div class="custom-control custom-checkbox small">
                            <input type="checkbox" class="custom-control-input" id="customCheck">
                            <label class="custom-control-label" for="customCheck">Remember
                                    Me</label>
                    </div>
            </div>
            <button [disabled]="signinForm.invalid" type="btn" class="btn btn-primary btn-user btn-block">
                    Login
            </button>
    </form>
    <hr>
    <div class="text-center">
            <a class="small" href="/reset">Resetear password</a>
    </div>
    <div class="text-center">
            <a class="small" href="/create">Crear una cuenta!</a>
    </div>
</div>
