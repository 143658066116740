import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {MatDialogModule} from '@angular/material/dialog';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { IndexComponent } from './views/index/index/index.component';
import { HomeComponent } from './views/home/home/home.component';
import { Step1Component } from './views/steps/step1/step1.component';
import { Step2Component } from './views/steps/step2/step2.component';
import { Step3Component } from './views/steps/step3/step3.component';
import { Step4Component } from './views/steps/step4/step4.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CreateNewAccountComponent } from './views/create-new-account/create-new-account.component';
import { ResetMyPasswordComponent } from './views/reset-my-password/reset-my-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { UnauthorizedStepComponent } from './views/unauthorized-step/unauthorized-step.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MyPortalsComponent } from './views/my-portals/my-portals.component';
import { PreferencesComponent } from './views/preferences/preferences.component';
import { StepsComponent } from './components/steps/steps.component';
import { SafePipePipe } from './src/app/views/steps/safe-pipe.pipe';
import { PortalComponent } from './views/my-portals/portal/portal.component';
import { UpdatePortalComponent } from './views/my-portals/update-portal/update-portal.component';
import { UpdatePortalTemplatesComponent } from './views/my-portals/update-portal-templates/update-portal-templates.component';
import { UpdatePortalFeaturesComponent } from './views/my-portals/update-portal-features/update-portal-features.component';
import { ListPortalPlansComponent } from './views/my-portals/list-portal-plans/list-portal-plans.component';
import { UpdatePortalPlanComponent } from './views/my-portals/update-portal-plan/update-portal-plan.component';
import { PlanComponent } from './views/my-portals/list-portal-plans/plan/plan.component';
import { UpdatePlanComponent } from './views/my-portals/list-portal-plans/update-plan/update-plan.component';
import { CreatePlanComponent } from './views/my-portals/list-portal-plans/create-plan/create-plan.component';
import { FacPopupComponent } from './views/steps/step4/fac-popup/fac-popup.component';
import { PaymentPopupComponent } from './views/steps/step4/payment-popup/payment-popup.component';
import { PreviewPopupComponent } from './views/steps/step3/preview-popup/preview-popup.component';



@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HomeComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    HeaderComponent,
    LoginComponent,
    CreateAccountComponent,
    ResetPasswordComponent,
    CreateNewAccountComponent,
    ResetMyPasswordComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    UnauthorizedStepComponent,
    MyPortalsComponent,
    PreferencesComponent,
    StepsComponent,
    SafePipePipe,
    PortalComponent,
    UpdatePortalComponent,
    UpdatePortalTemplatesComponent,
    UpdatePortalFeaturesComponent,
    ListPortalPlansComponent,
    UpdatePortalPlanComponent,
    PlanComponent,
    UpdatePlanComponent,
    CreatePlanComponent,
    FacPopupComponent,
    PaymentPopupComponent,
    PreviewPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true}), // ToastrModule added
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
