import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import {Location} from "@angular/common";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {PreviewPopupComponent} from "../../steps/step3/preview-popup/preview-popup.component";

@Component({
  selector: 'app-update-portal-features',
  templateUrl: './update-portal-features.component.html',
  styleUrls: ['./update-portal-features.component.css']
})
export class UpdatePortalFeaturesComponent implements OnInit {

  checked : any;
  pallete1 = false;
  pallete2 = false;
  pallete3 = false;
  pallete4 = false;
  exist = false;

  showPreview = false;
  urlClientPreview : any;

  logoName = null;
  logo64: any;
  logoType: any;

  principalName = null;
  principal64: any;
  principalType: any;

  promotionalName = null;
  promotional64: any;
  promotionalType: any;

  clickOnPreview = false;

  form: FormGroup ;
  onboardingConfigID: any;

  constructor(private dialog: MatDialog,private location : Location,private activateRoute : ActivatedRoute,private sanitizer: DomSanitizer,private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) {

    this.form = new FormGroup({
      id: new FormControl(''),
      page_title: new FormControl('', Validators.required),
      description_page: new FormControl('', Validators.required),
      price_title : new FormControl('', Validators.required),
      banner_title: new FormControl('', Validators.required),
      description_banner : new FormControl('', Validators.required)

    });

  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params =>{
      this.onboardingConfigID = params.id;
      this.getConfigByID();
    }, err =>{
      this.toast.showError(err);
    });
  }

  getConfigByID(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{

      this.exist = true;
      this.urlClientPreview = config.result.url_template + "/preview/" + config.result.service.id;
      if(config.result.color == "pal1"){
        this.checked = "pal1" ;
        this.pallete1 = true;
      }else if(config.result.color == "pal2"){
        this.checked = "pal2" ;
        this.pallete2 = true;
      }else if(config.result.color == "pal3"){
        this.checked = "pal3" ;
        this.pallete3 = true;
      }else{
        this.checked = "pal4" ;
        this.pallete4 = true;
      }

      if(config.result.onboardingPortalContent != null){
        this.form.controls["id"].setValue(config.result.onboardingPortalContent.id);
        this.form.controls["page_title"].setValue(config.result.onboardingPortalContent.page_title);
        this.form.controls["description_page"].setValue(config.result.onboardingPortalContent.description_page);
        this.form.controls["price_title"].setValue(config.result.onboardingPortalContent.price_title);
        this.form.controls["banner_title"].setValue(config.result.onboardingPortalContent.banner_title);
        this.form.controls["description_banner"].setValue(config.result.onboardingPortalContent.description_banner);
      }
      this.spinner.close();

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  selectPalette(event : any){
    this.checked = event.target.value;
  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logoName = file.name;
        this.logoType = file.type;
        this.logo64 = reader.result!.toString();
      };
    }
  }

  onPrincipalChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.principalName = file.name;
        this.principalType = file.type;
        this.principal64 = reader.result!.toString();
      };
    }
  }

  onPromotionalChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.promotionalName = file.name;
        this.promotionalType = file.type;
        this.promotional64 = reader.result!.toString();
      };
    }
  }

  sendConfiguration(){

    if(this.checked == undefined){
      return this.toast.showError("Por favor, seleccione una paleta de colores para el template");
    }

    if(this.showPreview){
      this.showPreview = false;
    }

    if(!this.exist){ // EN CASO DE QUE NO EXISTAN ENTONCES COMPROBAMOS QUE NO SE VAYAN VACIAS LAS IMAGENES
      if(this.logoName == null || this.logoName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su logo");
      }

      if(this.principalName == null || this.principalName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su banner principal");
      }

      if(this.promotionalName == null || this.promotionalName == undefined){
        return this.toast.showError("Por favor, seleccione una imagen para su banner promocional");
      }
    }

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario de Datos Generales");
    }

    this.spinner.open();

    let json = { config : this.onboardingConfigID, content : this.form.value, partner : JSON.parse(localStorage.getItem('sesion')!).partner, palete : this.checked, logo : { name : this.logoName, base64 : this.logo64, type : this.logoType}, principal : { name : this.principalName, base64 : this.principal64, type : this.principalType}, promotional : { name : this.promotionalName, base64 : this.promotional64, type : this.promotionalType}};

    this.webService.put(json,this.webService.HOST + "/onboarding/step3").subscribe( response =>{

        this.spinner.close();

        this.toast.showSuccess("Configuracion actualizada correctamente");

        if(!this.clickOnPreview){
          this.location.back();
        }else{
          this.clickOnPreview = false;
          this.showPreview = true;
          this.showPreviewPopup();
        }

      }, err =>{
        this.spinner.close();
        this.toast.showError(err)
      }
    );

  }


  preview(){
    this.clickOnPreview = true;
    this.sendConfiguration();
  }

  getUrlTemplatePreview(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlClientPreview);
  }

  showPreviewPopup(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PreviewPopupComponent, {
      width: '95%',
      disableClose: true,
      data: { url : this.urlClientPreview}
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      });
  }

}
