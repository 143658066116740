import { Component, OnInit } from '@angular/core';
import {PortalService} from "./portal.service";
import {WebService} from "../../services/web/web.service";
import { ToastService } from '../../services/notification/toast.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-my-portals',
  templateUrl: './my-portals.component.html',
  styleUrls: ['./my-portals.component.css']
})
export class MyPortalsComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer,private portalService : PortalService, private webService : WebService,private spinner : SpinnerService,private toast : ToastService) { }

  ngOnInit(): void {

    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    this.spinner.open();
    if(sesion.partner== null){
      this.spinner.close();
      return this.toast.showInfo("No posees portales creados, por favor crea tu primer portal");
    }

    this.webService.get(this.webService.HOST + "/onboarging/partner/" + sesion.partner).subscribe( portals =>{

      for( let portal of portals.result){
        let service = portal.service.id != null ? portal.service.id : portal.service;
        portal.url_template = portal.url_template + "/preview/" + service;
      }

      console.log(portals.result);

      this.portalService.portals.emit(portals.result)

      this.spinner.close()
    }, err =>{
      this.spinner.close();
      this.toast.showError(err)
    });
  }

}
