<div id="onboarding emptystate" >
      <div class="container-fluid emptystate-onboarding">
          <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
              <main class="emptystate-center">
          <p class="lead">Por favor, inicia sesión para continuar...</p>
                  <p class="lead">
                      <a href="/login" class="btn btn-primary btn-user btn-block">
                                             Login
                      </a>
                  </p>
              </main>
          </div>
      </div>
</div>