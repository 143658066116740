<div class="row">
    <div class="stepper">
        <div [ngClass] = "{ active : step1Active }" class="step one">
            <div class="number">1</div>
            <div class="title">
                <i></i>
                <p>Cuenta</p>
            </div>
        </div>
        <div [ngClass] = "{ active : step2Active }" class="step two">
            <div class="number">2</div>
            <div class="title">
                <i></i>
                <p>Portal</p>
            </div>
        </div>
        <div [ngClass] = "{ active : step3Active }" class="step three">
            <div class="number">3</div>
            <div class="title">
                <i></i>
                <p>Customizar</p>
            </div>
        </div>
        <div [ngClass] = "{ active : step4Active }"  class="step four">
            <div class="number">4</div>
            <div class="title">
                <i></i>
                <p>Mis planes</p>
            </div>
        </div>
    </div>
</div>