
<div id="onboarding login">

    <!-- Page Wrapper -->
    <div id="wrapper">
        <div class="container-fluid">

            <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
            <app-header></app-header>

            <app-steps [step] = "1"></app-steps>


            <div class="container main-account">
                <h2 class="title-sec">Configuración de cuenta</h2>

                <div class="card shadow mb-4">
                    <div class="card-header py-3">
                            <h4 class="m-0">Datos generales</h4>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="partnerForm" >

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Nombre de compañía*</label>
                                        <input [readOnly]="isNewService" [formControl]="partnerForm.controls['name_partner']" type="text" class="form-control" id="exampleFormControlInput1" placeholder="example">
                                        <small
                                        *ngIf="partnerForm.controls['name_partner'].hasError('required') && partnerForm.controls['name_partner'].touched"
                                        class="form-error-msg"> Nombre es requerido </small>

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">País*</label>
                                        <select   [formControl]="partnerForm.controls['country']" (change)="cityByCountry($event.target.value)"   style="width: 100%;" class="form-control" name="mes-exp" >

                                            <option [disabled]="isNewService" disabled="" selected="" value="">Seleccione una opcion</option>
                                            <option [disabled]="isNewService"  *ngFor="let country of countries" [value]="country.id" >{{ country.name_country }}</option>

                                        </select>


                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Moneda*</label>
                                        <select   [formControl]="partnerForm.controls['currency_id']"   style="width: 100%;" class="form-control" name="mes-exp"  >

                                            <option  selected="" value="">Seleccione una opcion</option>
                                            <option  *ngFor="let currency of currencies" [value]="currency.id"   >  {{ currency.codeCurrency }} - {{ currency.nameCurrency }}</option>

                                        </select>


                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Teléfono*</label>
                                        <input [readOnly]="isNewService" (keypress)="onlyNumberKey($event)" [formControl]="partnerForm.controls['phone_partner']"  type="text" class="form-control"  >

                                        <small
                                        *ngIf="partnerForm.controls['phone_partner'].hasError('required') && partnerForm.controls['phone_partner'].touched"
                                        class="form-error-msg"> Teléfono es requerido </small>

                                    </div>

                                    

                                </div>
                            <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Dirección*</label>
                                        <input [readOnly]="isNewService" [formControl]="partnerForm.controls['adress_partner']"   type="Text" class="form-control" >
                                        <small
                                        *ngIf="partnerForm.controls['adress_partner'].hasError('required') && partnerForm.controls['adress_partner'].touched"
                                        class="form-error-msg"> Dirección es requerido </small>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Ciudad*</label>
                                        <select [formControl]="partnerForm.controls['city']"  style="width: 100%;" class="form-control" name="mes-exp" >

                                            <option [disabled]="isNewService"  selected="" value="">Seleccione una opcion</option>
                                            <option [disabled]="isNewService"  *ngFor="let city of cities" [value]="city.id" >{{ city.name_city }}</option>

                                        </select>

                                        <small
                                        *ngIf="partnerForm.controls['city'].hasError('required') && partnerForm.controls['city'].touched"
                                        class="form-error-msg"> Ciudad es requerido </small>


                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Nombre del portal*</label>
                                        <input (keypress)="omit_special_char($event)" [readOnly]="exist"  [formControl]="partnerForm.controls['name_portal']"  type="text" class="form-control"  placeholder="">

                                      <p> Este nombre también será usado para crear el subdominio de su portal y se puede editar en cualquier momento.</p>

                                        <small
                                        *ngIf="partnerForm.controls['name_portal'].hasError('required') && partnerForm.controls['name_portal'].touched"
                                        class="form-error-msg"> Nombre de portal es requerido </small>

                                    </div>

                                </div>
                            </div>


                        </form>

                    </div>
                </div>

                <div *ngIf="isPartnerCreated" class="card shadow mb-4">
                    <div class="card-header py-3">
                            <h4 class="m-0">Datos bancarios</h4>
                    </div>
                    <div class="card-body">

                        <form [formGroup]="partnerForm" >
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Banco*   <span class="help"> <a  style="cursor: pointer;" (click)="sendContactMail(1)" class="nav-link px-2 link-secondary"> Abrir Cuenta </a></span> </label>
                                        <select  [formControl]="partnerForm.controls['bank']" style="width: 100%;" class="form-control" name="mes-exp" >

                                            <option disabled="" selected="" value="">Seleccione una opcion</option>
                                            <option  *ngFor="let bank of banks" [value]="bank.id" > {{ bank.name }}  - {{ bank.identificator }} </option>

                                        </select>

                                        <small
                                        *ngIf="partnerForm.controls['bank'].hasError('required') && partnerForm.controls['bank'].touched"
                                        class="form-error-msg"> Banco es requerido </small>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

                <div *ngIf="isPartnerCreated" class="card shadow mb-4">
                    <div class="card-header py-3">
                            <h4 class="m-0">Datos de notificaciones</h4>
                    </div>
                    <div class="card-body">

                        <form [formGroup]="partnerForm" >

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Número de WhatsApp* <span class="help"><a style="cursor: pointer;" (click)="sendContactMail(2)">Número personalizado</a></span>  </label>
                                        <select  [formControl]="partnerForm.controls['whatsappNumber']"  style="width: 100%;" class="form-control" name="mes-exp" >

                                            <option disabled="" selected="" value="">Seleccione una opcion</option>
                                            <option  *ngFor="let number of numbers" [value]="number.id"  >  {{ number.number }} </option>

                                        </select>

                                      <p>En caso de requerir habilitar un numero de whatsapp personalizado, por favor seleccione la opcion "Numero personalizado" y el personal autorizado estara comunicandose con usted </p>



                                      <small
                                        *ngIf="partnerForm.controls['whatsappNumber'].hasError('required') && partnerForm.controls['whatsappNumber'].touched"
                                        class="form-error-msg"> WhatsApp es requerido </small>

                                    </div>
                                </div>
                            <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Correo electrónico*<span class="help"><a (click)="sendContactMail(3)" style="cursor: pointer;" > Habilitar correo personalizado </a></span></label>
                                        <input [formControl]="partnerForm.controls['email_partner']" type="email" class="form-control"  placeholder="name@example.com" readonly>
                                        <small
                                        *ngIf="partnerForm.controls['email_partner'].hasError('required') && partnerForm.controls['email_partner'].touched"
                                        class="form-error-msg"> Email es requerido </small>

                                      <p>Por defecto, las comunicaciones a sus clientes estaran siendo enviadas mediante el correo : info@billcentrix.com. En caso de requerir habilitar un correo personalizado por favor selecciona "Habilitar correo personalizado" y el personal autorizado estara comunicandose con usted</p>


                                      <small
                                        *ngIf="partnerForm.controls['email_partner'].hasError('email') && partnerForm.controls['email_partner'].touched"
                                        class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>

                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>

                <div class="card shadow mb-4">
                    <div class="row buttons-onboard">
                        <div class="col-lg-4"> <a (click)="goBack()" class="btn back btn-user btn-block">Atras</a></div>
                        <div  class="col-lg-4">  </div>
                        <div class="col-lg-4">
                            <a (click)="update()" *ngIf="exist"  class="btn btn-primary btn-user btn-block">Guardar y continuar</a>
                            <a (click)="send()"  *ngIf="!exist"  class="btn btn-primary btn-user btn-block">Siguiente</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>
