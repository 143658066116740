import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FacPopupComponent} from '../fac-popup/fac-popup.component';
import { DomSanitizer} from '@angular/platform-browser';
import { config } from 'rxjs';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent implements OnInit {

  form: any ;
  errorNumber = true;
  numberRequired = false;
  id_process: any;
  onboardingPlan : any;
  configID: any;

  constructor(private activateRoute : ActivatedRoute,public dialogRef: MatDialogRef<PaymentPopupComponent>,
    private sanitizer: DomSanitizer,private dialog: MatDialog,private activatedRoute: ActivatedRoute,
    private webService : WebService, private toast : ToastService,
     private spinner : SpinnerService,
     @Inject(MAT_DIALOG_DATA) public data: any) {

    this.spinner.open();
    this.form = new FormGroup({
      plan: new FormControl('1', [
        Validators.required
      ]),
      numberCreditCard: new FormControl('', [ Validators.required
      ]),
      nameHolderCreditCard : new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required)
    });

    this.webService.get(this.webService.HOST + "/config/key/" + "billcentrix_onboarding_suscription_plan").subscribe(response =>{
      this.spinner.open();
      this.onboardingPlan = +response.result.value_config;
     
      this.activateRoute.queryParams.subscribe(queryParams =>{
       
        if(queryParams.config){
        
          this.configID = +queryParams.config;
        
         
        }else{
         
                this.configID = data.configID;
          
          
        }

        this.spinner.close();

      }, err =>{
        this.toast.showError(err);
      });


    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    })


    var sesion = JSON.parse(localStorage.getItem('sesion')!);
   

  }

  ngOnInit(): void {
    
  }


  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phoneNumber_customer"].setValue(obj);
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  send(){

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario");
    }

    this.spinner.open();

    const data = {
     creditcard:{
        numberCreditCard : this.form.value.numberCreditCard.trim(),
        nameHolderCreditCard : this.form.value.nameHolderCreditCard,
        expMonthCreditCard : this.form.value.expMonthCreditCard,
        expYearCreditCard : this.form.value.expYearCreditCard,
        ccv : this.form.value.ccv
      },
      plan : this.onboardingPlan,
      config : this.configID,
      param : 7
    }
    console.log('data',data);
    this.webService.post(data,this.webService.HOST + "/suscription").subscribe(response =>{

      this.spinner.close();
      if(response.htmlFormData){

        this.id_process = response.id;
        let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
          width: '50%',
          disableClose: true,
          data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: this.id_process }
        })
        dialogRef.afterClosed()
          .subscribe(res => {
            this.myLoadEvent();
          })

      }else{
        // EN ESTE CASO EL CHALLENGE NO FUE REQUERIDO, POR LO TANTO OBTENEMOS LA SUSCRIPCION EN EL SERVICIO
        if(response.result.status_customerPlan == 1 ){
  
          this.toast.showSuccess("Cobro procesado correctamente, pronto su portal estara en linea");
          this.activatePortal();

        }else{

          this.spinner.close();
          this.dialogRef.close();
          this.toast.showError("Su pago fue rechazado por el banco");

        }

      }

    }, err =>{
      console.log(err);
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  myLoadEvent(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/hasplan/" + this.id_process).subscribe(chp =>{
      if(chp.result.status_customerPlan  != 4){
        if(chp.result.status_customerPlan == 1 ){

          this.toast.showSuccess("Cobro procesado correctamente, pronto su portal estara en linea");
          this.activatePortal();

        }else{

          this.spinner.close();
          this.dialogRef.close();
          this.toast.showError("Su pago fue rechazado por el banco");

        }

      }
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  activatePortal(){
    this.spinner.open();
    this.webService.post({id: this.configID}, this.webService.HOST + "/onboarding/habilitate").subscribe( response =>{
      this.spinner.close();
      this.dialogRef.close();
      this.toast.showInfo("Felicidades, su dominio ha sido registrado. En breve estara disponible...");
      window.location.reload();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

}
