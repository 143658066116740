import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CreateNewAccountComponent } from './views/create-new-account/create-new-account.component';
import { HomeComponent } from './views/home/home/home.component';
import { IndexComponent } from './views/index/index/index.component';
import { MyPortalsComponent } from './views/my-portals/my-portals.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { PreferencesComponent } from './views/preferences/preferences.component';
import { ResetMyPasswordComponent } from './views/reset-my-password/reset-my-password.component';
import { Step1Component } from './views/steps/step1/step1.component';
import { Step2Component } from './views/steps/step2/step2.component';
import { Step3Component } from './views/steps/step3/step3.component';
import { Step4Component } from './views/steps/step4/step4.component';
import { UnauthorizedStepComponent } from './views/unauthorized-step/unauthorized-step.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import {UpdatePortalComponent} from "./views/my-portals/update-portal/update-portal.component";
import {
  UpdatePortalTemplatesComponent
} from "./views/my-portals/update-portal-templates/update-portal-templates.component";
import {
  UpdatePortalFeaturesComponent
} from "./views/my-portals/update-portal-features/update-portal-features.component";
import {ListPortalPlansComponent} from "./views/my-portals/list-portal-plans/list-portal-plans.component";
import {CreatePlanComponent} from "./views/my-portals/list-portal-plans/create-plan/create-plan.component";
import {UpdatePlanComponent} from "./views/my-portals/list-portal-plans/update-plan/update-plan.component";

const routes: Routes = [


  { path: 'login', component: IndexComponent },
  { path: 'portals',  canActivate: [AuthGuard] , component: MyPortalsComponent },
  { path: 'preferences',  canActivate: [AuthGuard] , component: PreferencesComponent },
  { path: 'create', component: CreateNewAccountComponent },
  { path: 'edit/portal/:id',  canActivate: [AuthGuard], component: UpdatePortalComponent},
  { path: 'edit/portal/:id/template',  canActivate: [AuthGuard] , component: UpdatePortalTemplatesComponent },
  { path: 'edit/portal/:id/features',  canActivate: [AuthGuard] , component: UpdatePortalFeaturesComponent },
  { path: 'edit/portal/:id/plans',  canActivate: [AuthGuard] , component: ListPortalPlansComponent },
  { path: 'edit/portal/:id/plans/create',  canActivate: [AuthGuard] , component: CreatePlanComponent },
  { path: 'edit/portal/:id/plans/update/:plan',  canActivate: [AuthGuard] , component: UpdatePlanComponent },
  { path: 'reset', component: ResetMyPasswordComponent },
  { path: '', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'step1',  canActivate:  [AuthGuard], component: Step1Component ,data: { step: 1}},
  { path: 'step2', canActivate:   [AuthGuard],  component: Step2Component ,data: { step: 2} },
  { path: 'step3', canActivate:   [AuthGuard],  component: Step3Component ,data: { step: 3} },
  { path: 'step4', canActivate:   [AuthGuard],  component: Step4Component ,data: { step: 4} },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'unauthorized-step', component: UnauthorizedStepComponent },
  { path: '**', component: NotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
