<div class="row">

  <div *ngFor=" let portal of myportals " class="col-lg-4">
    <div class="card card-sm card-transition h-100" >
      <iframe style="height: 400px;pointer-events: none;" [src]="getUrlTemplatePreview(portal.url_template)" ></iframe>
      <div class="card-body">
        <h2 class="card-title text-inherit">{{ portal.service.name_service }}</h2>
        <a style="float: left; cursor: pointer; color: blue"  (click)="preview(portal.url_template)" target="_blank" >Vista previa</a>
        <a style="float: right; cursor: pointer; color: blue" (click)="goEdit(portal)" >Editar</a>

      </div>
    </div>
  </div>

</div>
