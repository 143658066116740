<div id="onboarding login">

    <!-- Page Wrapper -->
    <div id="wrapper">
        <div class="container-fluid">

            <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
            <app-header></app-header>


            <app-steps [step] = "2"></app-steps>

            <div class="container main-account">
                <h2 class="title-se">Crear portal</h2>
                <p>Selecciona un tema</p>
                <div class="row">
                <div class="col-lg-4">
                    <div class="card card-sm card-transition h-100" href="../documentation/bs-icons.html" data-aos="fade-up">
                        <img class="card-img p-2" src="assets/img/l1.png" alt="Image Description" data-hs-theme-appearance="default">
                        <div class="card-body">
                            <h2 class="card-title text-inherit">Contemporary theme</h2>
                            <p class="card-text small text-body">Free, high quality, open source icon library with over 1,300 icons. Include them anyway you like—SVGs, SVG sprite, or web fonts.</p> <input   (click)="selectTemplate($event)" [checked]="template1"  name="radAnswer" [value]="1" type="radio" class="form-check-input portal">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card card-sm card-transition h-100" href="../documentation/bs-icons.html" data-aos="fade-up">
                        <img class="card-img p-2" src="assets/img/l2.png" alt="Image Description" data-hs-theme-appearance="default">
                        <div class="card-body">
                            <h2 class="card-title text-inherit">Practical theme</h2>
                            <p class="card-text small text-body">Free, high quality, open source icon library with over 1,300 icons. Include them anyway you like—SVGs, SVG sprite, or web fonts.</p> <input   (click)="selectTemplate($event)"  [checked]="template2" name="radAnswer" [value]="2" type="radio" class="form-check-input portal">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card card-sm card-transition h-100" href="../documentation/bs-icons.html" data-aos="fade-up">
                        <img class="card-img p-2" src="assets/img/l3.png" alt="Image Description" data-hs-theme-appearance="default">
                        <div class="card-body">
                            <h2 class="card-title text-inherit">Elegant theme</h2>
                            <p class="card-text small text-body">Free, high quality, open source icon library with over 1,300 icons. Include them anyway you like—SVGs, SVG sprite, or web fonts.</p> <input  (click)="selectTemplate($event)"  [checked]="template3" name="radAnswer"  [value]="3" type="radio" class="form-check-input portal">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row buttons-onboard">
                <div class="col-lg-4"> <a (click)="goBack()" class="btn back btn-user btn-block">Atras</a> </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <a *ngIf="exist" (click)="saveTemplate()" class="btn btn-primary btn-user btn-block">Guardar y continuar</a>
                    <a *ngIf="!exist" (click)="saveTemplate()" class="btn btn-primary btn-user btn-block">Siguiente</a></div>
            </div>
            </div>

        </div>

</div>

</div>
