<div id="onboarding login">

  <!-- Page Wrapper -->
  <div id="wrapper">


    <div class="container-fluid">
      <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
      <app-header></app-header>



      <div class="container">


        <div style="float: right" class="col-lg-4">
          <a routerLink="create" class="btn btn-primary btn-user btn-block">Crear nuevo plan</a>
        </div>

        <h2 class="title-se">Planes</h2>
        <p>Aquí podrás administrar los planes de tu portal</p>

          <app-plan></app-plan>

      </div>

    </div>

  </div>

</div>
