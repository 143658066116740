import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {

  checked : any;
  template1 = false;
  template2 = false;
  template3 = false;
  exist = false;
  onboardingConfigID : any;

  constructor(private activateRoute : ActivatedRoute,private router : Router,private location : Location,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }

  ngOnInit(): void {
    
    this.activateRoute.queryParams.subscribe( queryParams =>{
      if(queryParams.config){

        this.onboardingConfigID = queryParams.config;
        this.getConfigByID()

      }else{

        let sesion  = JSON.parse(localStorage.getItem('sesion')!);
        if(sesion.config !=  null){
          this.spinner.open();
          this.exist = true;
          if(sesion.config.length == 1){
            this.onboardingConfigID = sesion.config[0];
            this.getConfigByID();
          }

        }

      }
    }, err =>{
      this.toast.showError(err);
    });
  }

  getConfigByID(){
   
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      if(config.result.url_template === "https://dev-onboarding-practical.billcentric.com"){
        this.checked = 2;
        this.template2 = true;
      }else if (config.result.url_template === "https://dev-onboarding-contemporary.billcentric.com"){
        this.checked = 1;
        this.template1 = true;
      }else{
        this.checked = 3;
        this.template3 = true;
      }
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  selectTemplate(event : any){
    this.checked = event.target.value;
  }

  saveTemplate(){

      if(this.checked == undefined){
        this.toast.showError("Debes seleccionar un template para continuar");
      }

      this.spinner.open();
      let json = { partner : JSON.parse(localStorage.getItem('sesion')!).partner, config : this.onboardingConfigID , template : this.checked};
      this.webService.put(json,this.webService.HOST + "/onboarding/step2").subscribe( response =>{

        this.spinner.close();

        if(!this.exist){

          this.toast.showSuccess("Templates seleccionado correctamente");
          let array = []; // SE AGREGA ESL NUEVO MANEJO DE IDS PARA CONFIGS YA ES UN ARREGLO
          var sesion = JSON.parse(localStorage.getItem('sesion')!);
          // @ts-ignore
          array.push(response.id)
          sesion.config = array;
          localStorage.setItem('sesion',JSON.stringify(sesion));

        }else{
          this.toast.showSuccess("Template actualizado correctamente");
        }

        this.router.navigate(["/step3"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP3

      }, err =>{
          this.spinner.close();
          this.toast.showError(err)
        }
      )
  }

  goBack(){
    this.router.navigate(["/step1"], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

}
