<div id="onboarding login">

  <!-- Page Wrapper -->
  <div id="wrapper">
    <div class="container-fluid">

      <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
      <app-header></app-header>

      <div class="container main-account">
        <h2 class="title-sec">Customizar</h2>
        <div class="modulo card shadow mb-4">
          <div class="card-header py-3">
            <h4 class="m-0">Selecciona los colores</h4>
            <div class="row palette" >
              <div class="col-lg-4">
                <div class="card card-sm card-transition h-100"  data-aos="fade-up">
                  <img class="card-img p-2" src="assets/img/pal1.png" alt="Image Description" data-hs-theme-appearance="default">
                  <div class="card-body">
                    <h4 class="colors-t card-title text-inherit">Sunset palette</h4>
                    <input (click)="selectPalette($event)" value="pal1" [checked]="pallete1"  name="radAnswer"  type="radio" class="form-check-input portal">
                    <!-- Button trigger modal -->
                    <button type="button" class=" blockbtn btn" data-bs-toggle="modal" data-bs-target="#palette1">
                      Vista previa
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="palette1" tabindex="-1" aria-labelledby="#palette1" aria-hidden="true">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="palette1">Vista previa sunset palette</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <img class="preview"  src="assets/img/TPLA1.png">
                          </div>
                          <div class="modal-footer">

                            <button type="button" class="btn btn-primary">OK</button>
                          </div>
                        </div>
                      </div>
                    </div><!-- Modal -->
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-sm card-transition h-100"  data-aos="fade-up">
                  <img class="card-img p-2" src="assets/img/pal2.png" alt="Image Description" data-hs-theme-appearance="default">
                  <div class="card-body">
                    <h4 class="colors-t card-title text-inherit">Modern palette</h4>
                    <input (click)="selectPalette($event)" value="pal2" [checked]="pallete2"  name="radAnswer"  type="radio" class="form-check-input portal">
                    <!-- Button trigger modal -->
                    <button type="button" class=" blockbtn btn" data-bs-toggle="modal" data-bs-target="#palette2">
                      Vista previa
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="palette2" tabindex="-1" aria-labelledby="palette2" aria-hidden="true">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="palette1">Modern palette - Vista previa</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <img class="preview"  src="assets/img/TPLA2.png">
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-primary">Ok</button>
                          </div>
                        </div>
                      </div>
                    </div><!-- Modal -->
                  </div>

                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-sm card-transition h-100"  data-aos="fade-up">
                  <img class="card-img p-2" src="assets/img/pal3.png" alt="Image Description" data-hs-theme-appearance="default">
                  <div class="card-body">
                    <h4 class="colors-t card-title text-inherit">Violet palette</h4>
                    <input (click)="selectPalette($event)"  value="pal3" [checked]="pallete3"  name="radAnswer" type="radio" class="form-check-input portal">
                    <!-- Button trigger modal -->
                    <button type="button" class=" blockbtn btn" data-bs-toggle="modal" data-bs-target="#palette3">
                      Vista previa
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="palette3" tabindex="-1" aria-labelledby="palette3" aria-hidden="true">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="palette1">Violet palette - Vista previa</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <img class="preview"  src="assets/img/TPLA3.png">
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-primary">Ok</button>
                          </div>
                        </div>
                      </div>
                    </div><!-- Modal -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modulo row">
          <div class="col-lg-4">
            <div class="mb-3 inputup">
              <label for="formFile" class="form-label">Subir logo <span>SOLO archivos .svg</span></label>
              <input class="form-control" type="file" id="formFile" accept=".svg"  (change)="onLogoChange($event)" >
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mb-3 inputup">
              <label for="formFile" class="form-label">Subir banner principal <span>1440 x 720  px</span></label>
              <input class="form-control" type="file" id="formFile" accept="image/png, image/jpeg, image/svg"  (change)="onPrincipalChange($event)" >
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mb-3 inputup">
              <label for="formFile" class="form-label">Banner promocional <span>320 x 400  px</span></label>
              <input class="form-control" type="file" id="formFile" accept="image/png, image/jpeg, image/svg"  (change)="onPromotionalChange($event)" >
            </div>
          </div>
        </div>


        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h4 class="m-0">Datos generales del portal</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="form" >

              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Título de pagina*</label>
                    <input  [formControl]="form.controls['page_title']" type="text" class="form-control" id="exampleFormControlInput1" placeholder="Ingresa un título para tu portal">
                    <small
                      *ngIf="form.controls['page_title'].hasError('required') && form.controls['page_title'].touched"
                      class="form-error-msg"> Título es requerido </small>

                  </div>

                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Título para banner*</label>
                    <input [formControl]="form.controls['banner_title']"  type="text" class="form-control"  placeholder="Ingresa un título para tu banner">

                    <small
                      *ngIf="form.controls['banner_title'].hasError('required') && form.controls['banner_title'].touched"
                      class="form-error-msg"> Título es requerido </small>

                  </div>


                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Título para precio*</label>
                    <input [formControl]="form.controls['price_title']"  type="text" class="form-control"  placeholder="Ingresa un título para mostrar tus precios">

                    <small
                      *ngIf="form.controls['price_title'].hasError('required') && form.controls['price_title'].touched"
                      class="form-error-msg"> Título es requerido </small>

                  </div>



                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Descripción de página*</label>
                    <input [formControl]="form.controls['description_page']"   type="Text" class="form-control"  placeholder="Ingresa la descripcion que veran los tus clientes al entrar">
                    <small
                      *ngIf="form.controls['description_page'].hasError('required') && form.controls['description_page'].touched"
                      class="form-error-msg"> Descripción es requerido </small>
                  </div>

                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label"> Breve descripción para banner*</label>
                    <input [formControl]="form.controls['description_banner']"   type="Text" class="form-control"  placeholder="Ingresa una breve descripcion para mostrar en tu banner">
                    <small
                      *ngIf="form.controls['description_banner'].hasError('required') && form.controls['description_banner'].touched"
                      class="form-error-msg"> Descripción es requerido </small>
                  </div>

                </div>
              </div>


            </form>

          </div>
        </div>



        <div class="row buttons-onboard">
          <div class="col-lg-4"></div>
          <div class="guardar col-lg-4"><a (click)="preview()" class="btn back btn-user btn-block">Vista previa</a></div>
        </div>
        <div class="row buttons-onboard">
          <div class="col-lg-4"><a  routerLink=".." class="btn back btn-user btn-block">Atras</a></div>
          <div class="col-lg-4"></div>
          <div class="col-lg-4">
            <a (click)="sendConfiguration()" class="btn btn-primary btn-user btn-block">Guardar </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
