<div id="onboarding login">

    <!-- Page Wrapper -->
    <div id="wrapper">


			<div class="container-fluid">
				<!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
                <app-header></app-header>



				  <div class="container">


            <div style="float: right" class="col-lg-4">
              <a routerLink="/step1" [queryParams]="{new: true}" class="btn btn-primary btn-user btn-block">Crear nuevo portal</a>
            </div>

            <h2 class="title-se">Mis portales</h2>
            <p>Aquí podrás administrar tus portales</p>

              <app-portal></app-portal>

			    </div>

			</div>

    </div>

</div>
