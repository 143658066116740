<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
    <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
      <img src="assets/img/billcentrix.svg">
    </a>
          <div class="col-md-3 text-end">
      <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                  <li><a routerLink="/portals" class="nav-link px-2 link-secondary">Mis portales</a></li>
                  <li><a href="#" class="nav-link px-2 link-secondary">Reportes</a></li>
                  <li><a routerLink="/preferences" class="nav-link px-2 link-secondary">Preferencias</a></li>
                  <li><a (click)="logout()"  style="cursor: pointer;" class="nav-link px-2 link-secondary">Salir</a></li>
      </ul>
    </div>
  </header>
