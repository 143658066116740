<div id="onboarding login">
    <div id="wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6">
                        <!-- COMPONENTE PARA MANEJAR EL PROCESO CREAR UNA NUEVA CUENTA -->
                        <app-create-account> </app-create-account>

                </div>
                <div class="col-lg-6">
                    <div class="cover-login">
                    </div>
                </div>
            </div>	
        </div>
        
    </div>
</div>