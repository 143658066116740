import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from "@angular/router";
import { ToastService } from '../../services/notification/toast.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private auth : AuthService, private toast : ToastService ,private router : Router) { }

  ngOnInit(): void {
  }

  logout(){
    this.auth.closeSesion();
    this.toast.showSuccess("Ha cerrado su sesión correctamente...")
    this.router.navigateByUrl("/login");
  }

}
