import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../../services/notification/toast.service';
import { WebService}  from '../../../../services/web/web.service';
import {SpinnerService} from '../../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {Location} from "@angular/common";

@Component({
  selector: 'app-update-plan',
  templateUrl: './update-plan.component.html',
  styleUrls: ['./update-plan.component.css']
})
export class UpdatePlanComponent implements OnInit {

  plans : any = [];

  form: FormGroup ;

  frecuencies : any;

  imageName = null;
  image64: any;
  imageType: any;
  onboardingConfigID: any;
  planID: any;

  constructor(private location : Location,private activateRoute : ActivatedRoute, private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService ) {

    this.form = new FormGroup({
      id: new FormControl(''),
      name_plan: new FormControl('', Validators.required),
      feeCost_plan: new FormControl('', Validators.required),
      freezeCostPlan : new FormControl('', Validators.required),
      enrollmentCost_plan: new FormControl(0),
      frecuency : new FormControl('', Validators.required),
      status_plan : new FormControl(1, Validators.required),
      taxRate_plan : new FormControl('', Validators.required),
      service: new FormControl(''),
      description : new FormControl('', Validators.required),
      idPlan_partner : new FormControl("123", Validators.required),
      cobro_by_partner : new FormControl(false, Validators.required),
      verify_invoices_on_partner : new FormControl(false, Validators.required),
      charge_inmediately : new FormControl(false, Validators.required)
    });

  }

  ngOnInit(): void {

    this.spinner.open();
    this.webService.get(this.webService.HOST + "/frecuency").subscribe( response =>{
      this.frecuencies = response.result;
      this.activateRoute.params.subscribe(params =>{
        this.onboardingConfigID = params.id;
        this.planID = +params.plan;
        this.webService.get(this.webService.HOST + "/plan/" + this.planID).subscribe( response =>{
          this.form.controls["id"].setValue(response.result.id);
          this.form.controls["name_plan"].setValue(response.result.name_plan);
          this.form.controls["description"].setValue(response.result.description);
          this.form.controls["frecuency"].setValue(response.result.frecuency.id);

          this.form.controls["feeCost_plan"].setValue(response.result.feeCost_plan);
          this.form.controls["freezeCostPlan"].setValue(response.result.freezeCostPlan);
          this.form.controls["enrollmentCost_plan"].setValue(response.result.enrollmentCost_plan);
          this.form.controls["taxRate_plan"].setValue(response.result.taxRate_plan);
          this.form.controls["service"].setValue({id : response.result.service.id});
          this.form.controls["cobro_by_partner"].setValue(response.result.cobro_by_partner);
          this.form.controls["verify_invoices_on_partner"].setValue(response.result.verify_invoices_on_partner);
          this.form.controls["charge_inmediately"].setValue(response.result.charge_inmediately);


          this.spinner.close();
        }, err =>{
          this.spinner.close();
          this.toast.showError(err);
        });

      }, err =>{
        this.toast.showError(err);
        this.spinner.close();
      });

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });


  }

  send(){
    this.form.value.frecuency = { id : +this.form.value.frecuency }
    this.form.value.image64 = this.image64;
    this.form.value.imageName = this.imageName;
    this.form.value.imageType = this.imageType;
    this.form.value.onboarding_config = { id :  +this.onboardingConfigID };

    if(!this.form.valid){
      return this.toast.showError("Porfavor, complete el formulario...")
    }

    this.spinner.open();

    this.webService.put({plan:this.form.value, onboarding:true}, this.webService.HOST + "/partner/"+ this.planID +"/plan/" + this.planID ).subscribe(response =>{

      this.spinner.close();
      this.toast.showSuccess("Plan actualizado correactamente...");
      this.location.back();

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });


  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageName = file.name;
        this.imageType = file.type;
        this.image64 = reader.result!.toString();
      };
    }
  }

}
