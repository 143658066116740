import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../../services/web/web.service';
import { ToastService } from '../../services/notification/toast.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  signUpForm: FormGroup ;

  constructor(private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService) {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email])
    });
  }

  ngOnInit(): void {
  }

  singUp(){

    this.spinner.open();

    const signinData = this.signUpForm.value;

    // SOLICITAMOS TOKEN
    this.webService.post(signinData,this.webService.HOST + "/billcentrix/V2/user").subscribe( res =>{
      
      this.spinner.close();
      this.toast.showSuccess("Ha creado su cuenta correctamente, se enviara una clave a su correo, por favor inicie sesion con sus credenciales...");
      this.router.navigateByUrl("/login");

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    })



  }

}
