import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../../services/web/web.service';
import { ToastService } from '../../services/notification/toast.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  signinForm: FormGroup ;

  constructor(private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService, private auth : AuthService) {
    this.signinForm = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });
   }

  ngOnInit(): void {
  }

   // PARA REALIZAR PROCESO DE LOGIN
   signin() {

    this.spinner.open();

    const signinData = this.signinForm.value;

    let params = new HttpParams() ;

		params = params.set('grant_type', 'password');
		params = params.set('username', signinData.username);
		params = params.set('password', signinData.password);
		params = params.set('client_id', 'web_site');

    // SOLICITAMOS TOKEN
    this.webService.post(params,this.webService.HOST + "/oauth/token").subscribe( res =>{

      this.spinner.close();
      this.toast.showSuccess("Ha iniciado sesión correctamente");
      localStorage.setItem('sesion',JSON.stringify(res));
      let config = JSON.parse(localStorage.getItem('sesion')!).config;
      
      // EN CASO DE QUE NO TENGA o TENGA UNA SOLA CONFIGURACION ENTONCES LO REDIRIGIMOS A ESTE HOME
      if(config === Array){
        if(config.length > 1 ){ // EN CASO DE QUE TENGA MAS DE 1 PORTAL LO LLEVAMOS A SUS PORTALES
          this.router.navigateByUrl("/portals");
        }
      }

      this.router.navigateByUrl("/");


    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    })


  }

}
