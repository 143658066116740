<div id="onboarding login">

    <!-- Page Wrapper -->
    <div id="wrapper">
			<div class="container-fluid">
				

                <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
                <app-header></app-header>


				<div class="container preferences">
					<h2 class="title-sec">Editar preferencias</h2>

					<div class="card shadow mb-4">
						<div class="card-header py-3">
								<h4 class="m-0">Cambiar contraseña</h4>
						</div>
						<div class="card-body">
							<div class="row g-5">
								<div class="col-md-5 col-lg-4 order-md-last">
									<div class="card card-sm card-transition h-100 list-card" href="../documentation/bs-icons.html" data-aos="fade-up">
										<div class="card-body">
												<p class="card-title text-inherit">Recomendaciones</p>
											<ul class="check-list">
												<li>Una combinación de letras (a-z)</li>
												<li>Caracteres especiales</li>
												<li>Mayúsculas y minúsculas</li>
												<li>Números (0-9)</li>
												<li>Mínimo de 6 caracteres</li>
										</ul> 
											<div style="margin-top: 30px; "class="">
												<a (click)="send()" class="btn btn-primary btn-user btn-block">Guardar</a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-7 col-lg-8">
									<form [formGroup]="form" >
										<div class="row g-3">
											<div style="margin-top: 10px;" class="col-12">
												<label for="Name" class="form-label">Contraseña actual</label>
												<input autocomplete="actual_password" [formControl]="form.controls['actual_password']" type="password" class="form-control" >
												<small
												*ngIf="form.controls['actual_password'].hasError('required') && form.controls['actual_password'].touched" 
												class="form-error-msg"> Contraseña actual es requerido </small>
											</div>
											<div style="margin-top: 10px;" class="col-12">
												<label for="Name" class="form-label">Nueva contraseña</label>
												<input autocomplete="new_password" [formControl]="form.controls['new_password']" type="password" class="form-control" >

												<small
												*ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched" 
												class="form-error-msg"> Nueva contraseña es requerido </small>
						

											</div>
											<div style="margin-top: 10px;" class="col-12">
												<label for="Name" class="form-label">Repetir nueva contraseña</label>
												<input autocomplete="newPasswordConfirm" [formControl]="form.controls['newPasswordConfirm']" type="password" class="form-control" >

												<small
												*ngIf="form.controls['newPasswordConfirm'].hasError('required') && form.controls['newPasswordConfirm'].touched" 
												class="form-error-msg"> Campo requerido </small>
												<small *ngIf="form.controls['newPasswordConfirm'].hasError('equalTo') && form.controls['newPasswordConfirm'].touched " class="form-error-msg">Contraseñas no coinciden</small>

											</div>
										</div>
									</form>
								</div>
							</div>	
						</div>
					</div>
				</div>
			</div>
	</div>
    <!-- End of Page Wrapper -->



</div>