import { Component, OnInit } from '@angular/core';
import {PortalService} from "../portal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PreviewPopupComponent} from "../../steps/step3/preview-popup/preview-popup.component";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit {

  public myportals : any = [];

  constructor(private sanitizer: DomSanitizer,private dialog: MatDialog,private router : Router, private activateRoute : ActivatedRoute,private portalService : PortalService) { }

  ngOnInit(): void {
    this.portalService.portals.subscribe( portals =>{
      this.myportals = [];
      this.myportals.push(...portals);
    })
  }

  goEdit(portal : any){
    this.router.navigate(["/edit/portal/" + portal.id], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

  preview(url : any){

    let dialogRef: MatDialogRef<any> = this.dialog.open(PreviewPopupComponent, {
      width: '80%',
      height: '90%',
      disableClose: true,
      data: { url : url}
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      })
  }

  getUrlTemplatePreview(url : any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
