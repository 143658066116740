<div id="onboarding emptystate" >
    <div class="container-fluid emptystate-onboarding">
        <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
            <main class="emptystate-center">
        <p class="lead">404 - Página NO encontrada.</p>
                <p class="lead">
                    <button (click)="goBack()" class="btn btn-primary btn-user btn-block">
                                            Volver
                    </button>
                </p>
            </main>
        </div>
    </div>
</div>