<div id="onboarding login">

  <!-- Page Wrapper -->
  <div id="wrapper">
    <div class="container-fluid">

      <!-- COMPONENTE PARA MANEJAR EL HEADER DE LA APP -->
      <app-header></app-header>

      <div class="container main-account">
        <h2 class="title-sec">Crear plan</h2>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h4 class="m-0">Datos del plan</h4>
          </div>
          <div class="card-body">
            <div class="row g-5">
              <div class="col-12">
                <form [formGroup]="form" class="needs-validation" >
                  <div class="row g-3 plan-form">
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Nombre del plan*</label>
                      <input  [formControl]="form.controls['name_plan']" type="text" class="form-control" id="firstName" placeholder="" value="" required="">
                      <small
                        *ngIf="form.controls['name_plan'].hasError('required') && form.controls['name_plan'].touched"
                        class="form-error-msg"> Nombre es requerido </small>

                    </div>

                    <div class="col-sm-6">
                      <label for="firstName" class="form-label">%Impuesto*</label>
                      <input  [formControl]="form.controls['taxRate_plan']"  type="number" class="form-control" id="taxes" placeholder="" value="" required="">
                      <small
                        *ngIf="form.controls['taxRate_plan'].hasError('required') && form.controls['taxRate_plan'].touched"
                        class="form-error-msg"> Nombre es requerido </small>
                    </div>

                    <div class="col-sm-6">
                      <label for="firstName" class="form-label">Costo*</label>
                      <input [formControl]="form.controls['feeCost_plan']" type="number" class="form-control" id="cost" placeholder="" value="" required="">
                      <small
                        *ngIf="form.controls['feeCost_plan'].hasError('required') && form.controls['feeCost_plan'].touched"
                        class="form-error-msg"> Precio es requerido </small>
                    </div>

                    <div class="col-sm-6">
                      <label for="lastName" class="form-label">Frecuencia</label>

                      <select  [formControl]="form.controls['frecuency']" style="width: 100%;" class="form-control" name="mes-exp" >

                        <option disabled="" selected="" value="">Seleccione una opcion</option>
                        <option  *ngFor="let f of frecuencies" [value]="f.id" > {{ f.name_frecuency }} </option>

                      </select>

                      <small
                        *ngIf="form.controls['frecuency'].hasError('required') && form.controls['frecuency'].touched"
                        class="form-error-msg"> Frecuencia es requerido </small>
                    </div>

                    <!-- <div class="col-sm-6">
                      <label for="inscripción" class="form-label"> Costo por inscripcion*</label>
                      <input [formControl]="form.controls['enrollmentCost_plan']" type="number" class="form-control" id="enrollmentCost_plan" placeholder="" value="" required="">
                      <small
                        *ngIf="form.controls['enrollmentCost_plan'].hasError('required') && form.controls['enrollmentCost_plan'].touched"
                        class="form-error-msg"> Inscripcion es requerido </small>
                    </div> -->

                    <div class="col-sm-6">
                      <label for="congelamiento" class="form-label">Costo por Congelamiento</label>
                      <input  [formControl]="form.controls['freezeCostPlan']" type="number" class="form-control" id="freezeCostPlan" placeholder="" value="" required="">
                      <small
                        *ngIf="form.controls['freezeCostPlan'].hasError('required') && form.controls['freezeCostPlan'].touched"
                        class="form-error-msg"> Congelamiento es requerido </small>
                    </div>

                    <div class="col-12">
                      <label for="exampleFormControlTextarea1" class="form-label">Descripción del plan</label>
                      <textarea  [formControl]="form.controls['description']" class="form-control" id="descripcion" rows="3"></textarea>
                      <small
                        *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched"
                        class="form-error-msg"> Descripción es requerido </small>
                    </div>


                    <div class="col-sm-6">
                      <label for="congelamiento" class="form-label">Imagen del plan</label>
                      <input #inputFile class="form-control" type="file" id="formFile" accept="image/png, image/jpeg, image/svg"  (change)="onLogoChange($event)" >
                    </div>

                    <!--  <div class="guardar col-lg-4"><a  class="btn back btn-user btn-block">Guardar y vista previa</a></div> -->
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="add-plan col-lg-12"><a (click)="send()" class="btn back btn-user btn-block">Guardar</a></div>
        </div>

      </div>

    </div>
  </div>

</div>

