import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-update-portal-templates',
  templateUrl: './update-portal-templates.component.html',
  styleUrls: ['./update-portal-templates.component.css']
})
export class UpdatePortalTemplatesComponent implements OnInit {

  checked : any;
  template1 = false;
  template2 = false;
  template3 = false;
  onboardingConfigID : any;

  constructor(private activateRoute : ActivatedRoute,private router : Router,private location : Location,private toast : ToastService,private spinner : SpinnerService,private webService : WebService) { }

  ngOnInit(): void {

    this.activateRoute.params.subscribe( params =>{
      this.onboardingConfigID = params.id;
      this.getConfigByID();
    }, err =>{
      this.toast.showError(err);
    });

  }

  getConfigByID(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      if(config.result.url_template === "https://dev-onboarding-practical.billcentric.com"){
        this.checked = 2;
        this.template2 = true;
      }else if (config.result.url_template === "https://dev-onboarding-contemporary.billcentric.com"){
        this.checked = 1;
        this.template1 = true;
      }else{
        this.checked = 3;
        this.template3 = true;
      }
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  selectTemplate(event : any){
    this.checked = event.target.value;
  }

  saveTemplate(){

    if(this.checked == undefined){
      this.toast.showError("Debes seleccionar un template para continuar");
    }

    this.spinner.open();
    let json = { partner : JSON.parse(localStorage.getItem('sesion')!).partner, config : this.onboardingConfigID , template : this.checked};
    this.webService.put(json,this.webService.HOST + "/onboarding/step2").subscribe( response =>{

        this.spinner.close();
        this.toast.showSuccess("Template actualizado correctamente");
        this.location.back();

      }, err =>{
        this.spinner.close();
        this.toast.showError(err)
      }
    )
  }

}
