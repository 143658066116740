import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent implements OnInit {

  step1Active = false;
  step2Active = false;
  step3Active = false;
  step4Active = false;

  @Input() step  : number = 0 ;

  constructor() { }

  ngOnInit(): void {

    switch(this.step){
      case 1 : this.step1Active = true;
      break;
      case 2 : this.step2Active = true;
      break;
      case 3 : this.step3Active = true;
      break;
      case 4 : this.step4Active = true;
      break;
      default : console.log("NO HAY STEP CONFIGURADO");
    }
  
  }

}
