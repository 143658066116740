<div class="p-10">
    <div class="logo-login">
        <img src="assets/img/billcentrix.svg">
    </div>
    <div class="">
        <h1 class="h4 text-gray-900 mb-4">Resetear password</h1>
    </div>
    <form  [formGroup]="resetForm" (ngSubmit)="reset()"  class="user">
            <div class="form-group">

                    <input [formControl]="resetForm.controls['email']"  type="email" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Ingresa una dirección de email...">
                    <small 
                    *ngIf="resetForm.controls['email'].hasError('required') && resetForm.controls['email'].touched" 
                    class="form-error-msg"> Correo es requerido </small>
                    <small 
                    *ngIf="resetForm.controls['email'].hasError('email') && resetForm.controls['email'].touched" 
                    class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>

                </div>

            <button [disabled]="resetForm.invalid" class="btn btn-primary btn-user btn-block">
                    Resetear
            </button>
    </form>
    <hr>
    <div class="text-center">
            <a class="small" href="/login"> Loguearme</a>
    </div>
</div>
