<div class="p-10">
    <div class="logo-login">
        <img src="assets/img/billcentrix.svg">
    </div>
    <div class="">
        <h1 class="h4 text-gray-900 mb-4">Crear cuenta</h1>
    </div>
    <form class="user" [formGroup]="signUpForm" (ngSubmit)="singUp()" >
            <div class="form-group">
                    <input  [formControl]="signUpForm.controls['email']"  type="email" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Ingresa una dirección de email...">
                    <small 
                    *ngIf="signUpForm.controls['email'].hasError('required') && signUpForm.controls['email'].touched" 
                    class="form-error-msg"> Correo es requerido </small>
                    <small 
                    *ngIf="signUpForm.controls['email'].hasError('email') && signUpForm.controls['email'].touched" 
                    class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>
                </div>

            <button [disabled]="signUpForm.invalid" class="btn btn-primary btn-user btn-block">
                    Crear
            </button>
    </form>
    <hr>
    <div class="text-center">
            <a class="small" href="/login"> Loguearme</a>
    </div>
</div>
