import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PlanService} from "../plan.service";
import {WebService} from "../../../../services/web/web.service";
import {ToastService} from "../../../../services/notification/toast.service";
import {SpinnerService} from "../../../../services/spinner/spinner.service";

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  public myplans : any = [];

  constructor(private toast : ToastService,private spinner : SpinnerService,private webService : WebService ,private router : Router, private activateRoute : ActivatedRoute,private planService : PlanService) { }

  ngOnInit(): void {
    this.planService.plans.subscribe( plans =>{
      this.myplans = [];
      this.myplans.push(...plans);
    })
  }

  onUpdateStatus(id : number, value : number){

    if(value == 1){
      this.activatePlan(id)
    }else{
      this.deactivatePlan(id)
    }

  }

  activatePlan(id : number){
    this.spinner.open();
    this.webService.put({}, this.webService.HOST + "/billcentrix/V2/plan/" + id).subscribe( response =>{
      this.spinner.close();
      this.toast.showSuccess("Plan activado correctamente");
      window.location.reload();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err)
    });

  }

  deactivatePlan(id : number){
    this.spinner.open();
    this.webService.delete(this.webService.HOST + "/billcentrix/V2/plan/" + id).subscribe( response =>{
      this.spinner.close();
      this.toast.showSuccess("Plan desactivado correctamente");
      window.location.reload();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err)
    })
  }


}
